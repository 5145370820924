import "./FullPortfolio.css"
import image1 from "./portfolio/48B53966-2868-4132-825F-42092FEF4BD3.jpg";
import image2 from "./portfolio/61D96BFC-7536-444E-93DA-7525CD5651F0.jpg";
import image3 from "./portfolio/66D43CDC-21A4-4624-A2BB-E27BE15D258F.jpg";
import image4 from "./portfolio/AD042C53-2012-48E5-BA09-38646907C9A0.jpg";
import image5 from "./portfolio/B317E64E-A8E8-4A0E-BAF8-15C9E0909F22.jpg";
import image6 from "./portfolio/C47D3D37-D48F-4F2F-A040-1BFF779F8AB0.jpg";
import image7 from "./portfolio/727689DF-A931-4C8C-AB0F-E9116275BF81.jpg";
import image8 from "./portfolio/DSC02974.jpg";
import image9 from "./portfolio/IMG_0167.jpg";
import image10 from "./portfolio/IMG_1504.jpg";
import image11 from "./portfolio/IMG_4074.PNG";
import image12 from "./portfolio/IMG_4080.jpg";
import image13 from "./portfolio/IMG_4090.jpg"
import { NavLink, Link } from "react-router-dom";

function FullPortfolio() {
    const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9,image10,image11,image12,image13]
    return(
        <div id="FullPortfolio">
            <div className="header">
                <div className="text">
                    <em>Portfolio</em>
                    <h1>Browse our work</h1>
                </div>
                <div className="btns">
                    <NavLink to="/schedule">
                        <button>Book now</button>
                    </NavLink>
                    <Link to="https://www.instagram.com/bmdetail._/">
                        <button>Explore our instagram</button>
                    </Link>
                    <Link to="https://www.tiktok.com/@bmdetail._?is_from_webapp=1&sender_device=pc">
                        <button>Explore our TikTok</button>
                    </Link>
                </div>
            </div>
            <div className="images">
                {images.map(image => {
                    return(
                        <img
                            src={image}
                            key={images.indexOf(image)}
                            alt={images.indexOf(image)}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default FullPortfolio;
