import "./Schedule.css";
import { useState } from 'react';
import ScheduleAppointment from "./ScheduleAppointment";
import { IoIosArrowRoundForward } from "react-icons/io";
import { IoIosArrowRoundBack } from "react-icons/io";
import { IoIosStar } from "react-icons/io";
import { IoIosTime } from "react-icons/io";

import Total from "./Total";


function Schedule() {
    const [servicePackage, setServicePackage] = useState();
    const [addOnList, setAddOnList] = useState([]);
    const [page, setPage] = useState(0);
    const [packageValue, setPackageValue] = useState(0)

    function addService(e) {
        if (e.target.value === "1") {
            setPackageValue(100)
        } else if (e.target.value === "2") {
            setPackageValue(280)
        } else if (e.target.value === "3") {
            setPackageValue(250)
        } else if (e.target.value === "4") {
            setPackageValue(350)
        } else if (e.target.value === "5") {
            setPackageValue(500)
        }
        setServicePackage(e.target.value)

    }

    function removeService() {
        setServicePackage(0)
    }

    function addAddOn(e) {
        if (e.target.value === '1') {
            let oldValue = packageValue;
            setPackageValue(oldValue + 100)
        } else if (e.target.value === '2') {
            let oldValue = packageValue;
            setPackageValue(oldValue + 70)
        } else if (e.target.value === '3') {
            let oldValue = packageValue;
            setPackageValue(oldValue + 50)
        } else if (e.target.value === '4') {
            let oldValue = packageValue;
            setPackageValue(oldValue + 60)
        } else if (e.target.value === '5') {
            let oldValue = packageValue;
            setPackageValue(oldValue + 150)
        } else if (e.target.value === '6') {
            let oldValue = packageValue;
            setPackageValue(oldValue + 80)
        } else if (e.target.value === '7') {
            let oldValue = packageValue;
            setPackageValue(oldValue + 40)
        } else if (e.target.value === '8') {
            let oldValue = packageValue;
            setPackageValue(oldValue + 40)
        } else if (e.target.value === '9') {
            let oldValue = packageValue;
            setPackageValue(oldValue + 100)
        } else if (e.target.value === '10') {
            let oldValue = packageValue;
            setPackageValue(oldValue + 50)
        } else if (e.target.value === '11') {
            let oldValue = packageValue;
            setPackageValue(oldValue + 70)
        } else if (e.target.value === '12') {
            let oldValue = packageValue;
            setPackageValue(oldValue + 40)
        } else if (e.target.value === '14') {
            let oldValue = packageValue;
            setPackageValue(oldValue + 30)
        } else if (e.target.value === '15') {
            let oldValue = packageValue;
            setPackageValue(oldValue + 40)
        } else if (e.target.value === '16') {
            let oldValue = packageValue;
            setPackageValue(oldValue + 100)
        } else if (e.target.value === '17') {
            let oldValue = packageValue;
            setPackageValue(oldValue + 150)
        } else if (e.target.value === '18') {
            let oldValue = packageValue;
            setPackageValue(oldValue + 200)
        } else if (e.target.value === '19') {
            let oldValue = packageValue;
            setPackageValue(oldValue + 100)
        } else if (e.target.value === '20') {
            let oldValue = packageValue;
            setPackageValue(oldValue + 60)
        } else if (e.target.value === '21') {
            let oldValue = packageValue;
            setPackageValue(oldValue + 50)
        } else if (e.target.value === '22') {
            let oldValue = packageValue;
            setPackageValue(oldValue + 200)
        } else if (e.target.value === '23') {
            let oldValue = packageValue;
            setPackageValue(oldValue + 100)
        } else if (e.target.value === '24') {
            let oldValue = packageValue;
            setPackageValue(oldValue + 25)
        }

        setAddOnList(addOnList.concat(e.target.value));
    }

    function removeAddOn(e) {
        if (e.target.value === '1') {
            let oldValue = packageValue;
            setPackageValue(oldValue - 100)
        } else if (e.target.value === '2') {
            let oldValue = packageValue;
            setPackageValue(oldValue - 70)
        } else if (e.target.value === '3') {
            let oldValue = packageValue;
            setPackageValue(oldValue - 50)
        } else if (e.target.value === '4') {
            let oldValue = packageValue;
            setPackageValue(oldValue - 60)
        } else if (e.target.value === '5') {
            let oldValue = packageValue;
            setPackageValue(oldValue - 150)
        } else if (e.target.value === '6') {
            let oldValue = packageValue;
            setPackageValue(oldValue - 80)
        } else if (e.target.value === '7') {
            let oldValue = packageValue;
            setPackageValue(oldValue - 40)
        } else if (e.target.value === '8') {
            let oldValue = packageValue;
            setPackageValue(oldValue - 40)
        } else if (e.target.value === '9') {
            let oldValue = packageValue;
            setPackageValue(oldValue - 100)
        } else if (e.target.value === '10') {
            let oldValue = packageValue;
            setPackageValue(oldValue - 50)
        } else if (e.target.value === '11') {
            let oldValue = packageValue;
            setPackageValue(oldValue - 70)
        } else if (e.target.value === '12') {
            let oldValue = packageValue;
            setPackageValue(oldValue - 40)
        } else if (e.target.value === '14') {
            let oldValue = packageValue;
            setPackageValue(oldValue - 30)
        } else if (e.target.value === '15') {
            let oldValue = packageValue;
            setPackageValue(oldValue - 40)
        } else if (e.target.value === '16') {
            let oldValue = packageValue;
            setPackageValue(oldValue - 100)
        } else if (e.target.value === '17') {
            let oldValue = packageValue;
            setPackageValue(oldValue - 150)
        } else if (e.target.value === '18') {
            let oldValue = packageValue;
            setPackageValue(oldValue - 200)
        } else if (e.target.value === '19') {
            let oldValue = packageValue;
            setPackageValue(oldValue - 100)
        } else if (e.target.value === '20') {
            let oldValue = packageValue;
            setPackageValue(oldValue - 60)
        } else if (e.target.value === '21') {
            let oldValue = packageValue;
            setPackageValue(oldValue - 50)
        } else if (e.target.value === '22') {
            let oldValue = packageValue;
            setPackageValue(oldValue - 200)
        } else if (e.target.value === '23') {
            let oldValue = packageValue;
            setPackageValue(oldValue - 100)
        } else if (e.target.value === '24') {
            let oldValue = packageValue;
            setPackageValue(oldValue - 25)
        }
        const tempArray = [...addOnList];
        tempArray.splice(tempArray.indexOf(e.target.value.toString()), 1)
        setAddOnList(tempArray)
    }

    function goForward() {
        const currentPage = page;
        const nextPage = currentPage + 1;
        setPage(nextPage)
    }

    function goBack() {
        const currentPage = page;
        const previousPage = currentPage -1;
        setPage(previousPage);
    }

    if (page === 0) {
    return(
        <div className="Schedule">
            <div id="SelectPackage">
            <div className="container">
                <div className="sub">
                    <div className="tracker">1/3</div>
                    <div className="warning-message">
                        <h2 className="title">Select your package</h2>
                        <em>Starting prices vary on car size and condition</em>
                    </div>
                </div>
                <Total value={packageValue}/>
            </div>
            <div className="packages">
                <div className="top-packages">
                    <div className="package">
                        <div className="content">
                            <h3
                                className="title"
                            >
                                Standard Detail
                            </h3>
                            <div className="price">$100+</div>
                            <div className="time">
                                <IoIosTime className="time-icon"/>
                                1-2 hours
                            </div>
                            <ul>
                                <li>Rinse</li>
                                <li>Foam bath</li>
                                <li>Hand wash</li>
                                <li>Tire and wheel cleaning</li>
                                <li>Tire shine</li>
                                <li>Door jambs</li>
                                <li>Vaccum</li>
                                <li>Full Wipe Down</li>
                                <li>Outer Windows/Front Windshield</li>
                                <li>Car Scent</li>
                                <li>Spray Sealant</li>
                            </ul>
                        </div>
                        {servicePackage === '1' ?
                        <button
                        className='selected' onClick={removeService}>Selected</button> :
                            <button onClick={addService} value={1}>Select</button>
                        }
                    </div>
                    <div className="package">
                        <div className="content">
                            <h3 className="title">Mini Detail</h3>
                            <div className="price">$280+</div>
                            <div className="time">
                                <IoIosTime className="time-icon"/>
                                2.5-3 hours
                            </div>
                            <ul>
                                <li>Rinse</li>
                                <li>Foam bath</li>
                                <li>
                                    Hand wash
                                    <br></br>
                                    <em>&#40;2 bucket method&#41;</em>
                                </li>
                                <li>Iron decontamination</li>
                                <li>Deep tire and wheel cleaning</li>
                                <li>Wheel spray sealant</li>
                                <li>Tire shine</li>
                                <li>Air blow all exterior/interior</li>
                                <li>Door and Trunk jambs</li>
                                <li>Door jambs</li>
                                <li>Thorough vaccum</li>
                                <li>Thorough Wipe Down</li>
                                <li>Inside/Out Windows/Front Windshield</li>
                                <li>Car Scent</li>
                                <li>Plastic matt protector</li>
                                <li>
                                    Spray Sealant
                                    <br></br>
                                    <em>&#40;3 month protection&#41;</em>
                                </li>
                            </ul>
                        </div>
                        {servicePackage === '2' ?
                        <button
                        className='selected' onClick={removeService}>Selected</button> :
                            <button onClick={addService} value={2}>Select</button>
                        }
                    </div>
                    <div className="package">
                        <div className="content">
                            <h3 className="title">Exterior Detail</h3>
                            <div className="price">$250+</div>
                            <div className="time">
                                <IoIosTime className="time-icon"/>
                                2-3 hours
                            </div>
                            <ul>
                                <li>
                                    Rinse
                                    <br></br>
                                    <em>&#40;pre-foam, if needed&#41;</em>
                                </li>
                                <li>Foam bath</li>
                                <li>
                                    Hand wash
                                    <br></br>
                                    <em>&#40;2 bucket method&#41;</em>
                                </li>
                                <li>Iron decontamination</li>
                                <li>Deep tire, wheel, and barrels cleaning</li>
                                <li>
                                    Wheel spray sealant
                                    <br></br>
                                    <em>&#40;3-month&#41;</em>
                                </li>
                                <li>Tire shine</li>
                                <li>Air blow all exterior</li>
                                <li>Exhaust tips cleaning</li>
                                <li>Gas cap cleaning</li>
                                <li>Deep Door and Trunk jambs</li>
                                <li>Iron decontamination</li>
                                <li>Clay bar</li>
                                <li>6-month paint protection</li>
                                <li>Trim restoration</li>
                                <li>Engine bay detail</li>
                            </ul>
                        </div>
                        {servicePackage === '3' ?
                        <button
                        className='selected' onClick={removeService}>Selected</button> :
                            <button onClick={addService} value={3}>Select</button>
                        }
                    </div>
                    <div className="package">
                        <div className="content">
                            <h3 className="title">Deep Interior Detail</h3>
                            <div className="price">$350+</div>
                            <div className="time">
                                <IoIosTime className="time-icon"/>
                                3-4 hours
                            </div>
                            <ul>
                                <li>Deep Vaccum</li>
                                <li>Odor Removal</li>
                                <li>Air blow all surfaces</li>
                                <li>Steam Cleaning</li>
                                <li>
                                    Shamppoo hot extraction
                                    <br></br>
                                    <em>&#40;seats, carpets, & floormats&#41;</em>
                                </li>
                                <li>Deep Wipe down</li>
                                <li>Trunk deep cleaning</li>
                                <li>
                                    Leather treatment
                                    <br></br>
                                    <em>&#40;deep clean and condition&#41;</em>
                                </li>
                                <li>
                                    Plastic treatment
                                    <em>&#40;deep clean and condition&#41;</em>
                                </li>
                                <li>Headliner deep cleaning</li>
                                <li>Deep clean on door&truck jambs</li>
                                <li>All windows,mirrors, & screens</li>
                                <li>Scent bomb</li>
                                <li>Paper floors mat covers</li>
                            </ul>
                        </div>
                        {servicePackage === '4' ?
                        <button
                        className='selected' onClick={removeService}>Selected</button> :
                            <button onClick={addService} value={4}>Select</button>
                        }
                    </div>
                </div>
                <div className="premium-package">
                    <h3 className="title">
                        <IoIosStar size="25px" className="star"/>
                        PREMIUM DETAIL
                        <IoIosStar size="25px" className="star"/>
                    </h3>
                    <div className="price">$500+</div>
                    <div className="time">
                        <IoIosTime className="time-icon"/>
                        5-6 hours
                    </div>
                    <ul>
                        <li>Exterior + Interior Detail</li>
                    </ul>
                    {servicePackage === '5' ?
                        <button
                        className='selected' onClick={removeService}>Selected</button> :
                            <button onClick={addService} value={5}>Select</button>
                        }
                </div>
            </div>
            { servicePackage
                ? <div className="arrow">
                    <button className="next" onClick={goForward}>
                        Choose your add ons <IoIosArrowRoundForward size="60px" />
                    </button>
                </div>
                : <div className="space"></div>
            }
        </div>
        </div>
        )
    } else if (page === 1) {
        return(
            <>
            {/* <AddOns /> */}
            <div className="Schedule">
            <div id="AddOns">
            <div className="container">
                <div className="sub">
                    <div className="tracker">2/3</div>
                    <div className="warning-message">
                        <h2 className="title">Choose your add-ons</h2>
                        <em>Add-ons with ** indicate prices that depend on vehicle size</em>
                    </div>
                </div>
                <Total value={packageValue}/>

            </div>
            <div className="options">
                <ul>
                    <li>
                        <div>
                            <h3 className="option">Water Spot Removal<em>**</em></h3>
                            <div className="price">$100+</div>
                        </div>
                            {addOnList.includes('1')
                            ? <button className='selected' onClick={removeAddOn} value={1}>ADDED</button>
                            : <button onClick={addAddOn} value={1}>ADD</button>
                            }
                    </li>
                    <li>
                        <div>
                            <h3 className="option">Dog Hair Removal<em>**</em></h3>
                            <div className="price">$70+</div>
                        </div>
                        {addOnList.includes('2')
                        ? <button className='selected' onClick={removeAddOn} value={2}>ADDED</button>
                        : <button onClick={addAddOn} value={2}>ADD</button>
                        }
                    </li>
                    <li>
                        <div>
                            <h3 className="option">Trunk Cleaning<em>**</em></h3>
                            <div className="price">$50+</div>
                        </div>
                        {addOnList.includes('3')
                        ? <button className='selected' onClick={removeAddOn} value={3}>ADDED</button>
                        : <button onClick={addAddOn} value={3}>ADD</button>
                        }
                    </li>
                    <li>
                        <div>
                            <h3 className="option">Engine Detail</h3>
                            <div className="price">$85</div>
                        </div>
                        {addOnList.includes('4')
                        ? <button className='selected' onClick={removeAddOn} value={4}>ADDED</button>
                        : <button onClick={addAddOn} value={4}>ADD</button>
                        }
                    </li>
                    <li>
                        <div>
                            <h3 className="option">Extraction shampoo</h3>
                            <div className="price">$150+</div>
                        </div>
                        {addOnList.includes('5')
                        ? <button className='selected' onClick={removeAddOn} value={5}>ADDED</button>
                        : <button onClick={addAddOn} value={5}>ADD</button>
                        }
                    </li>
                    <li>
                        <div>
                            <h3 className="option">Leather Treatment</h3>
                            <div className="price">$80+</div>
                        </div>
                        {addOnList.includes('6')
                        ? <button className='selected' onClick={removeAddOn} value={6}>ADDED</button>
                        : <button onClick={addAddOn} value={6}>ADD</button>
                        }
                    </li>
                    <li>
                        <div>
                            <h3 className="option">Plastic Restoration</h3>
                            <div className="price">$40</div>
                        </div>
                        {addOnList.includes('7')
                        ? <button className='selected' onClick={removeAddOn} value={7}>ADDED</button>
                        : <button onClick={addAddOn} value={7}>ADD</button>
                        }
                    </li>
                    <li>
                        <div>
                            <h3 className="option">Chrome polishing</h3>
                            <div className="price">$70</div>
                        </div>
                        {addOnList.includes('8')
                        ? <button className='selected' onClick={removeAddOn} value={8}>ADDED</button>
                        : <button onClick={addAddOn} value={8}>ADD</button>
                        }
                    </li>
                    <li>
                        <div>
                            <h3 className="option">Headlight restoration</h3>
                            <div className="price">$100</div>
                        </div>
                        {addOnList.includes('9')
                        ? <button className='selected' onClick={removeAddOn} value={9}>ADDED</button>
                        : <button onClick={addAddOn} value={9}>ADD</button>
                        }
                    </li>
                    <li>
                        <div>
                            <h3 className="option">Steam Cleaning</h3>
                            <div className="price">$50+</div>
                        </div>
                        {addOnList.includes('10')
                        ? <button className='selected' onClick={removeAddOn} value={10}>ADDED</button>
                        : <button onClick={addAddOn} value={10}>ADD</button>
                        }
                    </li>
                    <li>
                        <div>
                            <h3 className="option">Clay bar<em>**</em></h3>
                            <div className="price">$80+</div>
                        </div>
                        {addOnList.includes('11')
                        ? <button className='selected' onClick={removeAddOn} value={11}>ADDED</button>
                        : <button onClick={addAddOn} value={11}>ADD</button>
                        }
                    </li>
                    <li>
                        <div>
                            <h3 className="option">Iron decontamination<em>**</em></h3>
                            <div className="price">$50+</div>
                        </div>
                        {addOnList.includes('12')
                        ? <button className='selected' onClick={removeAddOn} value={12}>ADDED</button>
                        : <button onClick={addAddOn} value={12}>ADD</button>
                        }
                    </li>
                    <li>
                        <div>
                            <h3 className="option">Paint correction</h3>
                            <div className="price"><em>Customers must call first to get a price.</em></div>
                        </div>
                        {addOnList.includes('13')
                        ? <button className='selected' onClick={removeAddOn} value={13}>ADDED</button>
                        : <button onClick={addAddOn} value={13}>ADD</button>
                        }
                    </li>
                    <li>
                        <div>
                            <h3 className="option">Child's Car Seat</h3>
                            <div className="price">$30</div>
                        </div>
                        {addOnList.includes('14')
                        ? <button className='selected' onClick={removeAddOn} value={14}>ADDED</button>
                        : <button onClick={addAddOn} value={14}>ADD</button>
                        }
                    </li>
                    <li>
                        <div>
                            <h3 className="option">Headliner Cleaning</h3>
                            <div className="price">$40</div>
                        </div>
                        {addOnList.includes('15')
                        ? <button className='selected' onClick={removeAddOn} value={15}>ADDED</button>
                        : <button onClick={addAddOn} value={15}>ADD</button>
                        }
                    </li>
                    <li>
                        <div>
                            <h3 className="option">Leather Coating </h3>
                            <div className="price">$100</div>
                        </div>
                        {addOnList.includes('16')
                        ? <button className='selected' onClick={removeAddOn} value={16}>ADDED</button>
                        : <button onClick={addAddOn} value={16}>ADD</button>
                        }
                    </li>
                    <li>
                        <div>
                            <h3 className="option">Window Coating</h3>
                            <div className="price">$150</div>
                        </div>
                        {addOnList.includes('17')
                        ? <button className='selected' onClick={removeAddOn} value={17}>ADDED</button>
                        : <button onClick={addAddOn} value={17}>ADD</button>
                        }
                    </li>
                    <li>
                        <div>
                            <h3 className="option">Wheel Coating </h3>
                            <div className="price">$200</div>
                        </div>
                        {addOnList.includes('18')
                        ? <button className='selected' onClick={removeAddOn} value={18}>ADDED</button>
                        : <button onClick={addAddOn} value={18}>ADD</button>
                        }
                    </li>
                    <li>
                        <div>
                            <h3 className="option">Trim Coating</h3>
                            <div className="price">$100</div>
                        </div>
                        {addOnList.includes('19')
                        ? <button className='selected' onClick={removeAddOn} value={19}>ADDED</button>
                        : <button onClick={addAddOn} value={19}>ADD</button>
                        }
                    </li>
                    <li>
                        <div>
                            <h3 className="option">Fabric Coating</h3>
                            <div className="price">$60</div>
                        </div>
                        {addOnList.includes('20')
                        ? <button className='selected' onClick={removeAddOn} value={20}>ADDED</button>
                        : <button onClick={addAddOn} value={20}>ADD</button>
                        }
                    </li>
                    <li>
                        <div>
                            <h3 className="option">Seatbelt</h3>
                            <div className="price">$50</div>
                        </div>
                        {addOnList.includes('21')
                        ? <button className='selected' onClick={removeAddOn} value={21}>ADDED</button>
                        : <button onClick={addAddOn} value={21}>ADD</button>
                        }
                    </li>
                    <li>
                        <div>
                            <h3 className="option">Oxidation removal</h3>
                            <div className="price">$200+</div>
                        </div>
                        {addOnList.includes('22')
                        ? <button className='selected' onClick={removeAddOn} value={22}>ADDED</button>
                        : <button onClick={addAddOn} value={22}>ADD</button>
                        }
                    </li>
                    <li>
                        <div>
                            <h3 className="option">Odor Removal Treatment</h3>
                            <div className="price">$100</div>
                        </div>
                        {addOnList.includes('23')
                        ? <button className='selected' onClick={removeAddOn} value={23}>ADDED</button>
                        : <button onClick={addAddOn} value={23}>ADD</button>
                        }
                    </li>
                    <li>
                        <div>
                            <h3 className="option">Air Cabin Filter</h3>
                            <div className="price">$25</div>
                        </div>
                        {addOnList.includes('24')
                        ? <button className='selected' onClick={removeAddOn} value={24}>ADDED</button>
                        : <button onClick={addAddOn} value={24}>ADD</button>
                        }
                    </li>
                </ul>
            </div>


            <div className="arrows">
                <button className="previous" onClick={goBack}>
                    <IoIosArrowRoundBack size="60px" /> Select package
                </button>
                <button className="next" onClick={goForward}>
                    Select date & time <IoIosArrowRoundForward size="60px" />
                </button>
            </div>
        </div>
        </div>
        </>
        )
    } else if (page === 2) {
        return(
            <div className="Schedule">
            <ScheduleAppointment
                servicePackage = {servicePackage}
                addOns = {addOnList}
                goBack = {goBack}
                value = {packageValue}
            />
            </div>

        )
    }


}

export default Schedule;
