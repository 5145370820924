import "./Footer.css"
// import { RiFacebookCircleFill } from "react-icons/ri";
import { RiInstagramFill, RiFacebookBoxFill,RiTiktokFill } from "react-icons/ri";
import { Link, NavLink } from "react-router-dom";
import logo from "./assets/logo-bg-removed.png"
import { MdOutlineEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";


function Footer() {
    return(
        <div id="Footer">
            <img src={logo} alt="logo"></img>
            <div className="info">
                <div>
                    <h4>Contact</h4>
                    <hr size="1" width="100%" color="#dbf059"></hr>
                </div>
                <div className="details">
                    <div className="detail">
                        <MdOutlineEmail className="icon" size="20px"/>
                        <Link
                            to="mailto:bmtopdetail@gmail.com"
                        >
                            bmtopdetail@gmail.com
                        </Link>
                    </div>
                    <div className="detail">
                        <FaPhoneAlt className="icon" size="20px" />
                        <Link to="tel:909-267-4462">

                        +1 (909)-267-4462
                        </Link>
                    </div>
                    <div className="detail">
                        <FaLocationDot className="icon" size="20px"/>
                        Servicing the IE
                    </div>
                </div>
            </div>
            <div className="menu">
                <div>
                    <h4>Navigate</h4>
                    <hr size="1" width="100%" color="#dbf059"></hr>
                </div>
                <div className="details">
                    <NavLink to="/">Main page</NavLink>
                    <NavLink to="/portfolio">Portfolio</NavLink>
                    <NavLink to="/faq">FAQ</NavLink>
                    <NavLink to="/contact">Contact us</NavLink>
                    <Link
                        to="https://www.google.com/search?kgmid=/g/11sf1gltqc&hl=en-US&q=Bm+Detail&shndl=30&source=sh/x/loc/osrp/m5/1&kgs=12d8508f7cc2f0c9#lrd=0xc5dfa771edc6309:0xc66ed47b2ad549ad,1,,,,"
                        target="_blank"
                    >
                        Google Reviews
                    </Link>
                </div>
            </div>
            <div className="follow-us">
                <div>
                    <h4>Follow us</h4>
                    <hr size="1" width="100%" color="#dbf059"></hr>
                </div>
                <div className="links">
                    <Link
                        to="https://www.instagram.com/bmdetail._/"
                        target="_blank"
                        aria-label="Check us out on Instagram"
                    >
                        <RiInstagramFill size="30px"/>
                    </Link>
                    <Link
                        to="https://www.facebook.com/profile.php?id=100078391600006&sk=about"
                        target="_blank"
                        aria-label="Check us out on Facebook">
                        <RiFacebookBoxFill size="30px" />
                    </Link>
                    <Link
                        to="https://www.tiktok.com/@bmdetail._?is_from_webapp=1&sender_device=pc"
                        target="_blank"
                        aria-label="Check us out on TikTok">
                        <RiTiktokFill size="30px"/>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Footer;
