import './Landing.css'
import banner from "./assets/6157982-hd_1920_1080_30fps.mp4";
import { NavLink } from 'react-router-dom';

function Landing() {
    return(
        <div id="Landing">
            <video autoPlay muted loop playsInline className="banner">
                <source src={banner} type="video/mp4" /> Your browser doesn't support the video tag.
            </video>
            <div className="container">
                <h1 className="tagline">Detailing at your doorstep.</h1>
                <div className="buttons">
                    <NavLink to="/schedule">
                        <button>Schedule now</button>
                    </NavLink>
                    <NavLink to="/contact">
                        <button>Call us</button>
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default Landing;
