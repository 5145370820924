import "./Contact.css";
import React, { useRef, useState } from 'react';
import car from "./assets/3C2C0B31-218F-4006-B881-9CE21840881B.jpg"
import { Link } from "react-router-dom";
import emailjs from '@emailjs/browser';


function Contact() {
    const form = useRef();
    const [success, setSuccess] = useState(false)
    const [fail, setFail] = useState(false)

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
        .sendForm('service_fbvs08i', 'template_a3t7t7p', form.current, {
            publicKey: 'r-9tAegqAPod8OzWs',
        })
        .then(
            () => {
            console.log('SUCCESS!');
            setSuccess(true)
            },
            (error) => {
            console.log('FAILED...', error.text);
            setFail(true)
            },
        );
    };
    return(
        <div id="Contact">
            <div className="left-container">
                <img src={car} alt="newly detailed car" />
            </div>
            <div className="right-container">
                <div className="text">
                    <h1>Contact us</h1>
                    <div className="description">
                        Have a question? Get in touch! We will get back to you in the next 24 hours.
                    </div>
                </div>
                {success === false && fail === false &&
                    <form ref={form} onSubmit={sendEmail}>
                    <div>
                        <div className="row">
                            <div className="input-column">
                                <label for="name">Name:</label>
                                <input
                                    type="text"
                                    placeholder="e.g. John Smith"
                                    name="name"
                                />
                            </div>
                            <div className="input-column">
                                <label for="email">Email:</label>
                                <input
                                    type="email"
                                    placeholder="johnsmith@gmail.com"
                                    name="email"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="input-column">
                        <label for="message">Message:</label>
                        <textarea
                            type="text"
                            placeholder="What would you like us to know..."
                            name="message"
                            rows= "100"
                        />
                    </div>
                    <button>Submit</button>
                </form>
                }
                {success &&
                <div className="success">
                    <em>
                    Thank you for reaching out! We will get back to you soon.
                    </em>
                </div>
                }
                {fail &&
                <div className="fail">
                    <em>
                    Message failed to send. &nbsp;
                    <u><strong>
                        <Link to="" refresh="true">Try again.</Link>
                    </strong></u>
                    </em>
                </div>
                }

                    <div>
                        <em>
                            You can also &nbsp;
                            <Link to="tel:9092674462">
                                <strong>call us</strong> &nbsp;
                            </Link>
                            or &nbsp;
                            <Link to="/schedule">
                                <strong>book now</strong>
                            </Link>

                        </em>
                    </div>
            </div>
        </div>
    )
}

export default Contact;
