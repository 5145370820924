// import Services from "./Services";
import Landing from "./Landing";
import AboutUs from "./AboutUs";
import Reviews from "./Reviews";
import Portfolio from "./Portfolio";
import Maps from "./Maps";
import Process from "./Process";
import WhyChooseUs from "./WhyChooseUs";

function SinglePage() {
    return(
        <>
        <div id="SinglePage">
            <Landing />
            <Process />
            {/* <Services /> */}
            <AboutUs />
            <WhyChooseUs />
            <Reviews />
            <Portfolio />
            <Maps />
        </div>
        </>
    )
}

export default SinglePage;
