import "./FullServices.css";
import ServicesList from "./ServicesList";
import { useState } from 'react';
import { NavLink } from "react-router-dom";

function FullService() {
    return(
        <div id="FullService">
            <div className="header">
                <em>Services</em>
                <h1>Learn about the services we offer</h1>
                <NavLink to="/schedule">
                    <button>Book now</button>
                </NavLink>
            </div>
            <div className="services">
                {ServicesList.map(service => {
                    return(
                        <ServiceContainer
                            service= {service.service}
                            description = {service.description}
                            packages= {service.packages}/>
                    )
                })}
            </div>
        </div>
    )
}

function ServiceContainer(props) {
    const [expand, setExpand] = useState(false)
        return (
            <>
            <div className="card">
                <h3 onClick={()=>setExpand(!expand)}>{props.service}</h3>
                {expand &&
                <>
                    <div className="description">{props.description}</div>
                    <div className="included-in">
                        <em>Included in: </em> {props.packages}
                    </div>
                </>
                }
            </div>
            </>
        )
    }


export default FullService;
