// import { useEffect, useState } from 'react';
import "./Reviews.css"
import reviews from "./GoogleReviews.json";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { RiStarSFill } from "react-icons/ri";


function Reviews() {

    function formatDate(reviewDate) {
        return format(reviewDate, 'MMMM dd, yyyy')
    }

    function getAverage(reviews) {
        let reviews_length = reviews.length;
        let total = 0;
        for (let review of reviews) {
            total += review.stars
        }
        return total/reviews_length;
    }

    return(
        <div id="Reviews">
            <div className="subheader"><em>Reviews</em></div>
            <h1 className="title">Hear from our satisfied customers</h1>
                <div className="avg-rating">
                    <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/1200px-Google_2015_logo.svg.png"
                        alt="google logo"
                    />
                    <div>
                        {
                            [...Array(parseInt(getAverage(reviews)))].map(() => {
                                return(
                                    <RiStarSFill
                                        color= "#ffc107"
                                        className="star"
                                    />
                                )
                            })
                        }
                    </div>
                    &#40; {getAverage(reviews)}.0 rating of {reviews.length} reviews&#41;
                </div>
                <div className="buttons">
                    <Link
                        to="https://www.google.com/search?kgmid=/g/11sf1gltqc&hl=en-US&q=Bm+Detail&shndl=30&source=sh/x/loc/osrp/m5/1&kgs=12d8508f7cc2f0c9#lrd=0xc5dfa771edc6309:0xc66ed47b2ad549ad,1,,,,"
                        target="_blank"
                    >
                        <button>View all</button>
                    </Link>
                    <Link
                        to="https://www.google.com/search?kgmid=/g/11sf1gltqc&hl=en-US&q=Bm+Detail&shndl=30&source=sh/x/loc/osrp/m5/1&kgs=12d8508f7cc2f0c9#lrd=0xc5dfa771edc6309:0xc66ed47b2ad549ad,3,,,,"
                        target="_blank"
                    >
                        <button>Leave a review</button>
                    </Link>
                </div>

            <ul className="reviews">
                {reviews.slice(0,6).map(review => {
                    return(
                            <li className="review-container" key={review.reviewId}>
                                <Link to={review.reviewUrl} target="_blank">
                                    <img
                                        className="user-pfp"
                                        src={review.reviewerPhotoUrl}
                                        alt={review.name}
                                    />
                                    <div className="right-container">
                                        <div className="name">
                                            <strong>{review.name}</strong>
                                        </div>
                                        <div className="stars-date">
                                            <div className="stars">
                                                {
                                                    [...Array(parseInt(review.stars))].map(() => {
                                                        return(
                                                            <RiStarSFill size="18px" color= "#ffc107"/>
                                                        )
                                                    })
                                                }

                                            </div>
                                            <div className="publishedAtDate">
                                                {formatDate(review.publishedAtDate)}
                                            </div>
                                        </div>
                                        <div className="review">
                                            {review.text}
                                        </div>
                                    </div>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }

export default Reviews;
