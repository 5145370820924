import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

function BeforeAfter(props) {
    const image1 = props.image1;
    const image2 = props.image2;
    return (
        <ReactCompareSlider
            className="slider"
            style={{ width: '100%', height: '100%', objectCover: "none" }}
            itemOne={<ReactCompareSliderImage src={image1} alt="Image one" />}
            itemTwo={<ReactCompareSliderImage src={image2} alt="Image two" />}
        />
    )
}

export default BeforeAfter;
