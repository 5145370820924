import "./AboutUs.css"
import aboutus from "./assets/2D845B5A-8D50-4462-8DC0-FC2CC6F5F625_1_201_a.jpeg";
import { NavLink } from "react-router-dom";

function AboutUs() {
    return(
        <div id="AboutUs">
            <div className="text">
                <div className="subheader"><em>About Us</em></div>
                <h1 className="title">Protecting your investments since 2021.</h1>
                <div className="description">
                    Bm Detail is a professional mobile detail company servicing all areas in the IE & surrounding areas. Our results speak for itself, 3+years of experience we bring the convenience to you! as we treat each vehicle as if it was our own. We offer many different services that best fits anyone’s needs & budget.

                    <br></br>

                    <br></br>

                    We have our own power , supplies ,& water.

                    <br></br>
                    <br></br>

                    We go to you anywhere you are!

                    <br></br>
                    <br></br>
                    At Bm Detail, we strive to deliver the best results possible and creating the best experience for our clients. 🎯
                </div>
                <NavLink to="/contact">
                    <button>Contact us</button>
                </NavLink>
            </div>
            <img className="washing" src={aboutus} alt="Brayan washing car"/>
        </div>
    )
}

export default AboutUs;
