const ServicesList = [
    {
        service: "Rinse",
        description: "...",
        packages: "Standard, Mini"},
    {
        service: "Foam bath",
        description: "Overall, foam baths offer a more thorough and gentle cleaning experience compared to traditional car washing methods.  foam clings to the dirt, grime, and other contaminants, making them easier to remove when you rinse off the car and  foam can act as a lubricant, reducing friction between the wash mitt and the paint, preventing scratches.",
        packages: "Standard, Mini, Exterior, Premium"
    },
    {
        service: "Hand wash",
        description: "...",
        packages: "Standard, Mini, Exterior, Premium"
    },
    {
        service: "Tire and wheel cleaning",
        description: "...",
        packages: "..."
    },
    {
        service: "Barrel cleaning",
        description: 'Barrel cleaning in car detailing refers to the process of cleaning the interior of the wheels, particularly the areas that are difficult to reach with a traditional wash mitt or brush. These areas, often referred to as "barrels" or "pockets," can accumulate dirt, grime, brake dust, and other contaminants that can be difficult to remove without specialized tools or techniques. Removing dirt and grime from the barrels can help to prevent corrosion, which can damage the wheels. Clean wheels can help to ensure optimal brake performance by preventing brake dust from accumulating on the brake rotors and calipers.',
        packages: "Exterior, Premium"
    },
    {
        service: "Wheel Spray Sealant",
        description: "Wheel spray sealant is a product used in car detailing to protect and enhance the appearance of your vehicle's wheels. It creates a protective barrier on the surface of the wheels, helping to repel water, dirt, brake dust, and other contaminants. Makes it easier to clean your wheels, as dirt and grime are less likely to adhere to the treated surface. Provides long-lasting protection, reducing the need for frequent cleaning and maintenance.",
        packages: "Mini, Exterior, Premium"
    },
    {
        service: "Tire Shine",
        description: "Tire shine is a product used in car detailing to enhance the appearance of your vehicle's tires. It adds a glossy, wet-look finish to the sidewalls, making them look new and attractive.  can help moisturize the rubber, preventing dryness and premature aging.",
        packages: "Standard, Mini, Exterior, Premium"
    },
    {
        service: "Jamb Cleaning",
        description: "Door jambs are exposed to the elements, including rain, snow, and salt, which can lead to corrosion. Cleaning and protecting the door jambs can help to prevent them from rusting and prolong their lifespan.",
        packages: "All packages"
    },
    {
        service: "Vacuum",
        description: "...",
        packages: "..."
    },
    {
        service: "Full wipe down",
        description: "...",
        packages: "..."
    },
    {
        service: "Outer Windows/Front",
        description: "...",
        packages: "..."
    },
    {
        service: "Windshield",
        description: "...",
        packages: "..."
    },
    {
        service: "Car Scent",
        description: "...",
        packages: "..."
    },
    {
        service: "Spray Sealant",
        description: "...",
        packages: "..."
    },
    {
        service: "Iron Decontamination",
        description: "Iron decontamination is a process used in car detailing to remove iron particles, such as rust, brake dust, and other contaminants, from the paintwork. These particles can etch into the clear coat and cause permanent damage if not removed. Iron decontamination can help restore the shine and luster of the paint and provides a clean surface for applying wax or sealant for added protection.",
        packages: "Mini, Exterior, Premium"
    },
    {
        service: "Air blow",
        description: "Air blowing in car detailing is a crucial step for achieving a clean and dry finish. For the exterior, It's used to remove water droplets and moisture from various surfaces, including the exterior paintwork, glass, wheels, and interior components, preventing water spots and streaking. For the interior, it Helps to dislodge dirt and debris that may be trapped in crevices and corners.",
        packages: "Mini, Exterior, Interior, Premium"
    },
    {
        service: "Plastic mat protector",
        description: "...",
        packages: "..."
    },
    {
        service: "Paper floors mat covers",
        description: "...",
        packages: "..."
    },
    {
        service: "Exhaust tip cleaning",
        description: "Exhaust tip cleaning is a process used to remove dirt, grime, and other contaminants from the exhaust tip of a vehicle. The exhaust tip is the part of the exhaust system that extends from the rear of the vehicle and is often visible. A clean exhaust tip can help prevent corrosion, which can weaken the exhaust tip and lead to premature failure.",
        packages: "Exterior, Premium"
    },
    {
        services: "Gas cap cleaning",
        description: "cleaning gas caps can contribute to the overall cleanliness and presentation of a vehicle. If left unchecked, dirt and grime can accumulate on the gas cap and contribute to corrosion. Cleaning the gas cap can help to prevent this and prolong its lifespan.",
        packages: "Exterior, Premium"
    },
    {
        services: "Clay bar",
        description: "A clay bar is a specialized cleaning tool used in car detailing to remove embedded contaminants from the paintwork that are too small to be removed with a wash mitt or sponge. These contaminants, such as tar, tree sap, industrial fallout, and bonded contaminants, can cause the paint to feel rough or gritty to the touch and can interfere with the application of wax or sealant. Clay bars can help to restore the original luster and shine of the paint. Great for cars that have been exposed to harsh elements or have been parked in areas with high levels of industrial pollution.",
        packages: "Exterior, Premium"
    },
    {
        service: "6-month paint protection",
        description: "...",
        packages: "..."
    },
    {
        service: "Trim Restoration",
        description: "Trim restoration is a process used in car detailing to restore the appearance of plastic and rubber trim pieces on a vehicle. Over time, these trim pieces can become faded, discolored, or cracked due to exposure to the elements, UV rays, and general wear and tear.",
        packages: "Exterior, Premium"
    },
    {
        service: "Engine bay detail",
        description: "Engine bay detailing is a process used to clean and maintain the engine compartment of a vehicle. A clean engine bay allows for easier future maintenance by making it easier to identidy and address potential problems and by preventing corrosion.",
        packages: "Exterior, Premium, Add-On"
    },
    {
        service: "Odor Removal",
        description: "...",
        packages: "..."
    },
    {
        service: "Steam cleaning",
        description: "Steam cleaning is a car detailing method that uses high-pressure steam to clean the interior and exterior surfaces of a vehicle. The steam penetrates deep into the fabric and upholstery, removing dirt, grime, bacteria, and odors. Steam can reach into crevices and corners that traditional cleaning methods may miss, providing a thorough clean. High temperatures can kill bacteria and germs, making it a more hygienic option. Steam can also help eliminate unpleasant odors caused by spills, pet hair, or smoke. Steam is gentle on most surfaces, including leather, fabric, and plastic.Environmentally friendly: Steam cleaning uses less water and chemicals compared to traditional cleaning methods.",
        packages: "Interior, Premium"
    },
    {
        service: "Shampoo hot extraction",
        description: "Shampoo hot extraction involves using a powerful extractor machine to deep clean the interior surfaces of a vehicle. A hot solution of cleaning shampoo is applied to the surface, and then extracted using a powerful vacuum. This process helps to remove dirt, grime, stains, and odors from the fabric and upholstery. Shampoo hot extraction is particularly effective at cleaning carpets and upholstery, which can be prone to dirt and stains.",
        packages: "Interior, Premium, Add-On"
    },
    {
        service: "Trunk Deep cleaning",
        description: "...",
        packages: "Interior, Premium, Add-On"
    },
    {
        service: "Leather Treatment",
        description: "...",
        packages: "Interior, Premium, Add-On"
    },
    {
        service: "Plastic Treatment",
        description: "...",
        packages: "Interior, Premium, Add-On"
    },
    {
        service: "Headliner Deep Cleaning",
        description: "Headliner deep cleaning is a process used to thoroughly clean the interior roof lining of a car. This area can accumulate dirt, dust, stains, and odors over time, especially if the vehicle is frequently used. Helps to eliminate unpleasant odors that may have accumulated in the headliner.",
        packages: "Interior, Premium"
    },
    {
        service: "Windows, mirrors, and screen cleaning",
        description: "...",
        packages: "..."
    },
    {
        service: "Dog Hair removal",
        description: "A specialized service offered by car detailers to remove pet hair from the interior of a vehicle.",
        packages: "Add-on"
    },
    {
        service: "Chrome Polishing",
        description: "Chrome polishing is a process used in car detailing to restore the shine and luster of chrome-plated surfaces on a vehicle. Chrome plating is a common finish for various exterior components, including bumpers, grilles, and trim pieces. Over time, chrome can become dull, scratched, or tarnished due to exposure to the elements, road debris, and general wear and tear.",
        packages: "Add-on"
    },
    {
        service: "Headlight restoration",
        description: "Headlight restoration is a process used to restore the clarity and brightness of faded or yellowed headlights. Over time, headlights can become cloudy or discolored due to exposure to the elements, UV rays, and road debris. This can reduce visibility at night, making it more difficult to see and be seen by other drivers. Headlight restoration not only improves your vehicles appearance, but increases your visibility and safety.",
        packages: "Add-on"
    }
]

export default ServicesList;
