import { useState } from 'react';
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import "./ImageSlider.css";
import BeforeAfter from './BeforeAfter';
import image1 from "./assets/IMG_8508.PNG";
import image2 from "./assets/IMG_8507.PNG";
import image3 from "./assets/IMG_8534.PNG";
import image4 from "./assets/IMG_8535.PNG";
import image5 from "./assets/54A3BD9C-2AE5-4651-8860-FB0A3E82EB93.jpg";
import image6 from "./assets/42E20416-310D-4670-A1B0-2022169229B1.jpg";
import image7 from "./assets/A3D80D10-8EED-4FE4-92CF-BD4B4C8C87B0.jpg";
import image8 from "./assets/60C90736-41B3-4599-BAF9-7B8067DC1575.jpg";
import image9 from "./assets/5AED5E3D-251B-488C-85D1-C166E18EDBFD_1_201_a.jpeg";
import image10 from "./assets/CAE8C14F-2B2D-479E-BEB5-2ABB8DDC600E_1_201_a.jpeg";

const comparisons =
    [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10];


function ImageSlider() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const images = [
        <BeforeAfter
            image1={comparisons[0]}
            image2={comparisons[1]} />,
        <BeforeAfter
            image1={comparisons[2]}
            image2={comparisons[3]} />,
        <BeforeAfter
            image1={comparisons[4]}
            image2={comparisons[5]} />,
        <BeforeAfter
            image1={comparisons[6]}
            image2={comparisons[7]} />,
        <BeforeAfter
            image1={comparisons[8]}
            image2={comparisons[9]} />,
    ]
    function slideForward() {
        if (currentIndex < images.length -1) {
            setCurrentIndex(currentIndex + 1)
        } else {
            setCurrentIndex(0)
        }
    }

    function slideBack() {
        if (currentIndex === 0) {
            setCurrentIndex(images.length - 1)
        } else {
            setCurrentIndex(currentIndex - 1)
        }
    }

    return(
        <>
            <div id="ImageSlider">
                <IoIosArrowBack
                    className="backward-btn"
                    onClick={slideBack}
                    color='white'/>
                       <div className='slide'>
                            {images[currentIndex]}
                        </div>

                <IoIosArrowForward
                    className="forward-btn"
                    onClick={slideForward}
                    color='white'
                />
            </div>


        </>
    )
}

export default ImageSlider;
